<template>
<!-- 云健身-家庭健身占比 -->
    <div class="baixing darkBack">
        <div class="baixingnav">
            <div>云健身区县人数排行</div>
        </div>
        <div id="jianoption2" style="width:5.80rem; height:3.40rem;margin-top:0.2rem;margin-left:-0.2rem;"></div>
    </div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";

export default {
    data () {
        return {
            sport: {
                data: [],
                number:[],
                max: 5,
            },
        }
    },
    methods: {
        // 柱状图
        fitness(id, number){
            let that = this;
            // let canye = this.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            // var data = number.number

            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    splitNumber: 6,
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: '#2D94D040',
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                grid: {         // 间距
                    left: '6%',  
                    right: '12%', 
                    top:'4%', 
                    bottom: '8%',  
                    containLabel: true,
                },
                yAxis: {
                    type: 'category',
                    data: number.data,
                    inverse: true,              // 上下反向
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    // max: number.max,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    axisLabel: {               // y轴字体的颜色
                        // margin: 176,//刻度标签与轴线之间的距离
                        interval:0,//横轴信息全部显示 
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E2",
                            fontSize: '0.13rem',
                            // align: 'left'
                        },                           
                    },
                },
                series: [{
                    realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    barWidth : '40%',              // 柱子的宽度
                    data: number.number,
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: "#2D94D0" // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#3AE6CE" // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        color: '#3AE6CE',    // 柱状图上字体的颜色
                        fontSize:'0.13rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };

            canye.setOption(option);

            //图表自动缩放
            window.addEventListener("resize",function(){
            	canye.resize();
            });
        }
    },
    mounted () {
        // this.fitness("jianoption2", this.sport)
        let that = this;
        // this.fitness("jianoption1", this.sport)
        var data = {
            token: window.getToken(),
            app_id: window.appId,
            time: timestamp(),
        }

        let url = window.appHost+"/api/v1/screen/Cloud_Gym/area"
        let result = window.ajaxRequest(url,JSON.stringify(data),'post')
        // console.log(result)
        that.sport.data = [];
        that.sport.number = [];
        for(let i in result.data){
            // console.log(i,result.data[i])
            this.sport.data.push(i);
            this.sport.number.push(result.data[i]);
        }
        that.sport.max = result.data.length;
        that.fitness("jianoption2", that.sport)

    }
}
</script>

<style scoped>
.baixing{
    width:5.97rem;
    height:4.35rem;
    font-size: 0.20rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}


</style>